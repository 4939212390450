import React from 'react'

/**
 * Displays a styled DateBox
 */
class DateBox extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const dateTime = new Date(this.props.dateIso8601);
        const dateArray = dateTime.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric'}).split('/');
        const date = {
            dayNumeric: dateArray[1],
            day: dateTime.toLocaleDateString('de-DE', {weekday: 'long'}),
            monthNumeric: dateArray[0],
            month: dateTime.toLocaleDateString('de-DE', {month: 'long'}),
            year: dateArray[2],
        }

        return (
            <div className="mews-DateBox">
                <span className="day-numeric">{date.dayNumeric}</span>
                <span className="month">{date.month}</span> <span className="year">{date.year}</span>
                <span className="day">{date.day}</span>
            </div>
        );
    }
}

export default DateBox