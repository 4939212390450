import MewsApi from "../service/MewsApi";
import moment from "moment-timezone";

class CabRate {

    /**
     */
    constructor(cabAvailability, rateIndex) {

        this.cabAvailability = cabAvailability;
        this.rateIndex = rateIndex;
    }

    /**
     * @todo prüfen, ob die Rate gebucht werden kann, wenn sie nicht über den ganzen Zeitraum verfügbar ist.
     */
    isAvailable() {

        return true;

        if( this.cabAvailability.getMewsResponse().length === 0 ) {

            return false;
        }

        if( this.cabAvailability.getMewsResponse().RoomCategoryAvailabilities.length === 0 ) {

            return false;
        }

        const responseData = this.cabAvailability.getMewsResponse();
        const requestedRoomCount = this.cabAvailability.getCabs().length;
        const availableRoomCount = responseData.RoomCategoryAvailabilities[0].AvailableRoomCount;

        if (requestedRoomCount > availableRoomCount ) {

            return false;
        }

        return true;
    }

    getCabAvailability() {

        return this.cabAvailability;
    }

    getName() {

        return this.cabAvailability.getMewsResponse().Rates[ this.rateIndex ].Name["de-DE"];
    }

    getDescription() {

        return this.cabAvailability.getMewsResponse().Rates[ this.rateIndex ].Description["de-DE"];
    }

    getOverallTotalPrice() {

        if ( !this.isAvailable() ) {

            return false;
        }

        let totalPrice = 0.00;

        this.cabAvailability.getCabs().map((cab) => {

            totalPrice += this.getCabTotalPrice(cab);
        });

        return totalPrice;
    }

    /**
     *
     * @param cab
     * @return {number}
     */
    getCabTotalPrice(cab) {

        let cabTotalPrice = 0.00;

        this.cabAvailability.getMewsResponse().RoomCategoryAvailabilities[0].RoomOccupancyAvailabilities.map((price) => {

            if(price.AdultCount === cab.availabilityRequest.personCount) {

                cabTotalPrice = price.Pricing[ this.rateIndex ].Price.Total.EUR;
            }
        });

        return cabTotalPrice;
    }
}

export default CabRate;