import React from 'react'
import CurrencyFormat from 'react-currency-format';
import MewsApi from "../service/MewsApi";
import Popup from "reactjs-popup";

/**
 * Shows the availability
 */
class AvailabilityBox extends React.Component {

    constructor(props) {
        super(props);
    }

    getRateHtml(rate, index) {
        return (
                <div className="mews-RateItem">
                    <h3 className="title">{rate.getName()}</h3>
                    <p className="description">{rate.getDescription()}</p>
                    <div className="fromPrice">
                        <span className="from">ab</span>&nbsp;
                        <CurrencyFormat
                            value={rate.getOverallTotalPrice()}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={'text'}
                            decimalSeparator={','}
                            thousandSeparator={'.'}
                            suffix={' €'} />
                    </div>
                </div>
        )
    }

    render() {

        const availability = this.props.cabAvailability;

        if( !availability.isAvailable() ) {

            return (
                <div className="mews-availabilityBox not-available"><p>In dem Zeitraum ist alles ausgebucht. Bitte w&auml;hle einen anderen Zeitraum.</p></div>
            );
        }

        let ratesHtml = availability.getRates().map(this.getRateHtml);

        return (
            <div className="mews-availabilityBox available">
                <div className="mews-RateList">
                    {ratesHtml}
                </div>
                <Popup
                    trigger={<button className="btn btn-primary btn-lg mews-availabilityBox-distLink">Jetzt Buchen</button>}
                    position="top center">
                    <div>
                        <p><strong>Wir leiten dich jetzt auf unser Buchungstool weiter</strong><br/><br/>Bitte beachte, auf der nächsten Seite noch einmal deine gewünschte Rate und die Cabin- &amp; Personenanzahl zu prüfen!</p>
                        <a className="btn btn-primary btn-lg mews-availabilityBox-distLink" href={MewsApi.getDistributorLink(availability)} target="_blank">OK</a>
                    </div>
                </Popup>
            </div>
        );
    }
}

export default AvailabilityBox