import moment from "moment-timezone";
import CabAvailability from "../Model/Cab";

class MewsApi {

    /**
     *
     * @param config
     */
    constructor(config = {}) {

        this.apiUrl = 'https://www.mews.li';
        this.apiClient = 'CabinskiClient 0.0.1';
        this.apiHotelId = '8098f7fb-b3f2-4e83-9a86-ab0b00973af1';
        this.availabilities = null;

        //this.apiUrl = 'https://demo.mews.li';
        //this.apiHotelId = '3edbe1b4-6739-40b7-81b3-d369d9469c48';
    }

    static getApiUrl () {

        return new MewsApi().apiUrl;
    }

    static getHotelId () {

        return new MewsApi().apiHotelId;
    }

    /**
     *
     * @return {moment}
     */
    static getNowMoment() {

        return moment(this.getNowDate());
    }

    /**
     *
     * @return {Date}
     */
    static getNowDate() {

        const nowDate = new moment();
        const dateString = nowDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
        const now = new Date(dateString);

        return now;
    }

    /**
     *
     * @param date
     * @param daysToAdd
     * @return {*}
     */
    static addDaysToDate(date, daysToAdd) {

        const mom = moment(date);
        mom.add( daysToAdd, "day" );

        return mom.toDate();
    }

    /**
     *
     * @param date
     * @param daysToSubtract
     * @return {*}
     */
    static subtractDaysFromDate(date, daysToSubtract) {

        const mom = moment(date);
        mom.subtract( daysToSubtract, "day" );

        return mom.toDate();
    }

    /**
     *
     * @return {Date}
     */
    static getMinStartDate() {

        return this.getMinStartMoment().toDate();
    }

    /**
     *
     * @return {*}
     */
    static getMinStartMoment() {

        let startDay = this.getNowMoment();
        const start16072020 = moment('2020-07-16');

        if (!startDay.isSameOrAfter(start16072020)) {

            startDay = start16072020;
        }

        while (startDay.day() !== 4 && startDay.day() !== 0) {

            startDay.add(1, 'day');
        }

        return startDay;
    }

    /**
     *
     * @return {moment.Moment}
     */
    static getMinEndMoment() {

        return MewsApi.getMinStartMoment();
    }

    /**
     *
     * @return {Date}
     */
    static getMinEndDate() {

        return this.getEndDate( this.getMinStartDate(), this.getMinStartDate());
    }

    /**
     *
     * @param startDate
     * @param endDate
     */
    static getEndDate(startDate, minDate, mode = 'NEXT') {

        
        const startMoment = moment(startDate);
        const minMoment = moment(minDate);
        const endMoment = startMoment;

        let i = 0;

        do {

            if ((mode === 'NEXT' && endMoment.isAfter(minMoment)) || (mode === 'PREV' && endMoment.isBefore(minMoment))) {

                switch (true) {

                    case startMoment.day() === 4 && endMoment.day() === 0:
                        return endMoment.toDate();
                        break;

                    case startMoment.day() === 4 && endMoment.day() === 4:
                        return endMoment.toDate();
                        break;

                    case startMoment.day() === 0 && endMoment.day() === 4:
                        return endMoment.toDate();
                        break;

                    case startMoment.day() === 0 && endMoment.day() === 0:
                        return endMoment.toDate();
                        break;

                    default:
                        break;
                }
            }

            if (mode === 'NEXT') {

                endMoment.add(1, "day");

            } else {

                endMoment.subtract(1,"day");
            }

            i++;

        } while (i < 365);
    }


    /**
     * Returns if the date is a possible starting date for the booking
     *
     * @param date
     * @returns {boolean}
     */
    static isPossibleStartDate(date) {

        if (date == null) {

            return false;
        }

        const day = date.getDay();

        return day === 4 || day === 0;
    }

    /**
     *
     * @param parameter
     * @returns {*}
     */
    buildPostRequest(parameter) {

        return {

            method: 'POST',
            body: JSON.stringify(parameter),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
    }

    /**
     * @todo testen
     */
    fetchAvailability(cabAvailability, callback) {

        const availabilityParams = {

            Client: this.apiClient,
            HotelId: this.apiHotelId,
            StartUtc: cabAvailability.getStartDateUTC().toISOString(),
            EndUtc: cabAvailability.getEndDateUTC().toISOString(),
            VoucherCode: cabAvailability.voucherCode
        }

        console.log('availabilityParams', availabilityParams);

        fetch(this.apiUrl + '/api/distributor/v1/hotels/getAvailability', this.buildPostRequest(availabilityParams))
            .then(response => response.json())
            .then(data => {

                console.log('response', data)

                cabAvailability.setMewsResponse(data);

                callback(cabAvailability)
            });
    }

    fetchVoucherValidation(voucherCode, callback) {

        const requestParams = {

            Client: this.apiClient,
            HotelId: this.apiHotelId,
            VoucherCode: voucherCode
        }

        console.log('Voucher requestParams', requestParams);

        fetch(this.apiUrl + '/api/distributor/v1/vouchers/validate', this.buildPostRequest(requestParams))
            .then(response => response.json())
            .then(data => {

                callback(data.IsValid)
            });
    }

    static getDistributorLink(cabAvailability) {

        const mews = new MewsApi();

        let distributorLink = mews.apiUrl
            + '/distributor/'
            + mews.apiHotelId
            + '?mewsStart=' + cabAvailability.getStartDateUTC().toISOString().slice(0,10)
            + '&mewsEnd=' + cabAvailability.getEndDateUTC().toISOString().slice(0,10)
            + '&mewsRoute=rates'
            + '&mewsRoom=4c8a4470-77c7-4e07-9170-ab0b00973c68'
            + '&currency=EUR';

        if( cabAvailability.voucherCode ) {

            distributorLink += '&mewsVoucherCode=' + cabAvailability.voucherCode;
        }

        return distributorLink;
    }
}

export default MewsApi;