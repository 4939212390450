import MewsApi from "../service/MewsApi";
import moment from "moment-timezone";
import CabRate from "./CabRate";

class CabAvailability {

    /**
     */
    constructor(cabs, starDate, endDate, voucherCode) {

        this.maxCabCount = 10;
        this.cabs = cabs;
        this.startDate = starDate;
        this.endDate = endDate;
        this.voucherCode = voucherCode;
        this.rates = [];
        this.mews = {

            response: {

                data: {},
                error: {}
            }
        };
    }

    setMewsResponse(data) {

        this.mews.response.data = data;
        this.assignRates();
    }

    getMewsResponse() {

        return this.mews.response.data;
    }

    getCabs() {
        return this.cabs;
    }

    isAvailable() {

        if( this.mews.response.data.length === 0 ) {

            return false;
        }

        if( this.mews.response.data.RoomCategoryAvailabilities.length === 0 ) {

            return false;
        }

        const responseData = this.mews.response.data;
        const requestedRoomCount = this.cabs.length;
        const availableRoomCount = responseData.RoomCategoryAvailabilities[0].AvailableRoomCount;

        if (requestedRoomCount > availableRoomCount ) {

            return false;
        }

        return true;
    }

    getOverallTotalPrice() {

        if ( !this.isAvailable() ) {

            return false;
        }

        let totalPrice = 0.00;

        this.cabs.map((cab) => {

            totalPrice += this.getCabTotalPrice(cab);
        });

        return totalPrice;
    }

    getRates() {

        return this.rates;
    }

    assignRates() {

        this.rates = [];

        for (var i = 0; i < this.mews.response.data.Rates.length; i++) {

            this.rates.push( this.buildRate(i) );
        }
    }

    buildRate(index) {

        return new CabRate(this, index)
    }

    /**
     *
     * @param cab
     * @return {number}
     */
    getCabTotalPrice(cab) {

        const requestedPersons = cab.availabilityRequest.personCount;
        let cabTotalPrice = 0.00;

        this.mews.response.data.RoomCategoryAvailabilities[0].RoomOccupancyAvailabilities.map((price) => {

            if(price.AdultCount === cab.availabilityRequest.personCount) {

                console.log(price.Pricing[0].Price.Total.EUR);

                cabTotalPrice = price.Pricing[0].Price.Total.EUR;
            }
        });

        return cabTotalPrice;
    }

    getStartDateUTC() {

        const startDateUTC = new moment(this.startDate);

        startDateUTC.add(this.startDate.getTimezoneOffset() * -1, 'minutes');

        return startDateUTC.toDate();
    }

    getEndDateUTC() {

        const endDateUTC = new moment(this.endDate);

        endDateUTC.add(this.endDate.getTimezoneOffset() * -1, 'minutes');

        return endDateUTC.toDate();
    }
}

export default CabAvailability;