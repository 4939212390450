import MewsApi from "../service/MewsApi";

class Cab {

    /**
     */
    constructor() {

        this.identifier = Date.now();
        this.maxPersonCount = 4;
        this.availabilityRequest = {

            personCount: 2
        }
    }
}

export default Cab;