import React from 'react'
import AvailabilityBox from "./AvailabilityBox";

/**
 * Handles the availability selection
 */
class AvailabilitySection extends React.Component {

    constructor(props) {
        super(props);

        this.handleRequestClick = this.handleRequestClick.bind(this);
    }

    handleRequestClick() {

        this.props.onAvailabilityRequestClick();
    }

    render() {

        if( this.props.availabilityIsLoading ) {

            return (
                <div className="mews-AvailabilitySection">
                    <div className="mews-AvailabilitySection-isLoading"></div>
                </div>
            );
        }

        if( !this.props.cabAvailability.hasOwnProperty('cabs') ) {

            return (
                <div className="mews-AvailabilitySection">
                    <button className="btn btn-primary btn-lg mews-endDateSelector-prev" onClick={this.handleRequestClick}>Verfügbarkeit prüfen</button>
                </div>
            );
        }

        return (
            <div className="mews-AvailabilitySection">
                <AvailabilityBox
                    cabAvailability={this.props.cabAvailability }
                />
            </div>
        );
    }
}
export default AvailabilitySection