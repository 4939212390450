import React from 'react'
import AvailabilityBox from "./AvailabilityBox";

/**
 * Handles the availability selection
 */
class AttributeSection extends React.Component {

    constructor(props) {
        super(props);

        this.handleVoucherCodeChange = this.handleVoucherCodeChange.bind(this);
        this.handleVoucherCommitmentClick = this.handleVoucherCommitmentClick.bind(this);
    }

    handleVoucherCodeChange(inputElement) {

        this.props.onVoucherCodeChange(inputElement);
    }

    handleVoucherCommitmentClick(inputElement) {

        this.props.onVoucherCommitmentClick();
    }

    render() {

        console.log('test', this.props.voucherCode != '' && this.props.voucherIsValid);

        let voucherWrapperCssClass = '';

        if( this.props.voucherIsValidated && !this.props.voucherIsValid ) {

            voucherWrapperCssClass = 'mews-AttributeSelectSection-Voucher-Wrapper is-invalid';

        } else if( this.props.voucherIsValidated && this.props.voucherIsValid ) {

            voucherWrapperCssClass = 'mews-AttributeSelectSection-Voucher-Wrapper is-valid';

        } else {

            voucherWrapperCssClass = 'mews-AttributeSelectSection-Voucher-Wrapper';
        }

        return (
            <div className="mews-AttributeSelectSection">
                <div className={voucherWrapperCssClass}>
                    <div className="input-group input-group-l">
                        <input
                            type="text"
                            className="form-control mews-AttributeSelectSection-VoucherBtn"
                            placeholder="Gutscheincode"
                            onChange={this.handleVoucherCodeChange}
                        />
                        <span className="input-group-btn">
                            <button
                                onClick={this.handleVoucherCommitmentClick}
                                className="btn btn-default"
                                type="button">hinzuf&uuml;gen</button>
                        </span>
                    </div>
                    <div className="mews-VoucherInfo">
                        <span className={"invalid"}>Der Gutscheincode "{this.props.voucherCode}" ist nicht korrekt und wird nicht bei der Verf&uuml;gbarkeit berücksichtigt.</span>
                        <span className={"valid"}>"{this.props.voucherCode}" wird ber&uuml;cksichtigt.</span>
                    </div>
                </div>
            </div>
        );
    }
}
export default AttributeSection