import React from 'react'
import DateBox from "./DateBox";
import DatePicker from "react-datepicker/es";
import MewsApi from "../service/MewsApi";

/**
 * Handles the start date selection
 */
class StartDateSelector extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const StartDateSelector = ({ value, onClick }) => {

            const startDate = value ? new Date(value) : new Date();

            return (

                <button className="mews-date-btn" onClick={onClick}>
                    <div className="mews-startDate"><DateBox dateIso8601={startDate.toISOString()} /></div>
                </button>

            );
        }

        return (
            <div className="mews-startDateSelector">
                <DatePicker
                    selected={this.props.startDate}
                    onChange={date => this.props.onStartDateChange(date)}
                    filterDate={MewsApi.isPossibleStartDate}
                    placeholderText="Select a weekday"
                    minDate={MewsApi.getMinStartDate()}
                    customInput={<StartDateSelector />}
                    popperPlacement="top"
                />
            </div>

        );
    }
}

export default StartDateSelector