import React from 'react';
import './App.css';

import "react-datepicker/dist/react-datepicker.css";
import MewsApi from './service/MewsApi';
import StartDateSelector from "./components/StartDateSelector";
import EndDateSelector from "./components/EndDateSelector";
import AvailabilitySection from "./components/AvailabilitySection";
import NumericInput from 'react-numeric-input';
import CabAvailability from "./Model/CabAvailability";
import Cab from "./Model/Cab";
import CabsSelector from "./components/CabsSelector";
import AttributeSection from "./components/AttributeSection";

/**
 * Init MewsComponet
 */
class App extends React.Component {

    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {

            startDate: MewsApi.getMinStartDate(),
            endDate: MewsApi.getMinEndDate(),
            cabs: [new Cab()],

            cabAvailabilityIsLoading: false,
            cabAvailability: {},

            voucherCode: '',
            voucherIsValidating: false,
            voucherIsValid: false,
            voucherIsValidated: false,
        };

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleAvailabilityRequestClick = this.handleAvailabilityRequestClick.bind(this);

        this.handleAddCabClick = this.handleAddCabClick.bind(this);
        this.handleRemoveCabClick = this.handleRemoveCabClick.bind(this);
        this.handlePersonCountChange = this.handlePersonCountChange.bind(this);

        this.handleVoucherCodeChange = this.handleVoucherCodeChange.bind(this);
        this.handleVoucherCodeCommitmentClick = this.handleVoucherCodeCommitmentClick.bind(this);
    }

    /**
     * @param startDate
     */
    handleStartDateChange(startDate) {

        const minDate = MewsApi.addDaysToDate(startDate, 1);
        const endDate = MewsApi.getEndDate(startDate, minDate, 'NEXT');

        this.setState({
            startDate: startDate,
            endDate: endDate,
            cabAvailability: {}
        });
    }

    /**
     * @param endDate
     */
    handleEndDateChange(endDate) {

        this.setState({
            endDate: endDate,
            cabAvailability: {}
        });
    }

    /**
     *
     */
    handleAvailabilityRequestClick() {

        this.setState({
            cabAvailabilityIsLoading: true
        });

        const voucherCode = this.state.voucherIsValid
            ? this.state.voucherCode
            : '';

        const api = new MewsApi();
        const cabAvailability = new CabAvailability(
            this.state.cabs,
            this.state.startDate,
            this.state.endDate,
            voucherCode)

        api.fetchAvailability( cabAvailability, (cabAvailability) => {

                this.setState({

                    cabAvailabilityIsLoading: false,
                    cabAvailability: cabAvailability
                })
            }
        )
    }

    /**
     * @param value
     */
    handleAddCabClick() {

        const cabs = this.state.cabs;
        cabs.push( new Cab() );

        this.setState({
            cab: cabs,
            cabAvailability: {}
        });
    }

    /**
     * @param cabIdentifier
     */
    handleRemoveCabClick( cabIdentifier ) {

        console.log('call - handleRemoveCabClick');

        if( this.state.cabs.length === 1 ) {

            return;
        }

        const filteredCabs = this.state.cabs.filter(function (cab) {

            return (cab.identifier !== cabIdentifier);
        });

        this.setState({
            cabs: filteredCabs,
            cabAvailability: {}
        });
    }

    /**
     * @param cabIdentifier
     */
    handlePersonCountChange( value, valueAsString, input, cabId ) {

        const cabs = this.state.cabs;

        console.log('value', value);
        console.log('cabIdentifier', cabId);

        cabs.forEach((cab) => {

            if (cab.identifier === cabId) {

                cab.availabilityRequest.personCount = value;
            }
        });

        this.setState({
            cabs: cabs,
            cabAvailability: {}
        });
    }

    handleVoucherCodeChange(inputElement) {

        this.setState({

            voucherCode: inputElement.target.value,
            cabAvailability: {}
        });
    }

    handleVoucherCodeCommitmentClick() {

        this.setState({

            voucherIsValidating: true,
            cabAvailability: {}
        });

        const api = new MewsApi();

        api.fetchVoucherValidation( this.state.voucherCode, (isValid) => {

                this.setState({

                    voucherIsValidating: false,
                    voucherIsValid: isValid,
                    voucherIsValidated: true
                })
            }
        )
    }

    /**
     *
     * @return {*}
     */
    render() {

        return (
            <div className='mews-distributor-widget'>
                <div className="mews-DateSelectSection">
                    <span className="mews-fromTo-arrow"></span>
                    <StartDateSelector
                        onStartDateChange={this.handleStartDateChange}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                    <EndDateSelector
                        onEndDateChange={this.handleEndDateChange}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                </div>
                <div className="mews-CabSelectSection">
                    <CabsSelector
                        cabs={this.state.cabs}
                        onAddCabClick={this.handleAddCabClick}
                        onRemoveCabClick={this.handleRemoveCabClick}
                        onPersonCountChange={this.handlePersonCountChange}
                    />
                </div>
                <AttributeSection
                    onVoucherCodeChange={this.handleVoucherCodeChange}
                    onVoucherCommitmentClick={this.handleVoucherCodeCommitmentClick}
                    voucherCode={this.state.voucherCode}
                    voucherIsValid={this.state.voucherIsValid}
                    voucherIsValidated={this.state.voucherIsValidated}

                />
                <AvailabilitySection
                    onAvailabilityRequestClick={this.handleAvailabilityRequestClick}
                    availabilityIsLoading={this.state.cabAvailabilityIsLoading}
                    cabAvailability={this.state.cabAvailability}
                />
            </div>
        );
    }
}

export default App;
