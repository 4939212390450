import React, { Component } from "react";
import Cab from "../Model/Cab";
import NumericInput from "react-numeric-input";

class CabsSelector extends Component {

    constructor(props) {
        super(props);

        this.createCab = this.createCab.bind(this);
    }

    createCab(cab, index) {

        const cabId = cab.identifier

        return (
            <div className="mews-CabsSelector-Item">
                <div className="mews-CabsSelector-ItemRemove">
                    <button className="mews-CabsSelector-ItemRemove-btn" onClick={() => {this.props.onRemoveCabClick(cabId)}}></button>
                </div>
                <div className="mews-CabsSelector-ItemTitle">
                    <strong>{index + 1}. CABIN</strong>
                </div>
                <div className="mews-CabsSelector-ItemPersonCount">
                    <NumericInput
                        target={cab.identifier}
                        value={cab.availabilityRequest.personCount}
                        mobile
                        size={4}
                        min={1}
                        max={cab.maxPersonCount}
                        onChange={(value, valueToString, input) => {this.props.onPersonCountChange(value, valueToString, input, cabId)}}
                        className="form-control"
                        format={(num) => {return num + ' Pers.';}}
                    />
                </div>
            </div>
        )
    }

    render() {

        let listItems = this.props.cabs.map(this.createCab);

        return (
            <div className="mews-CabsSelector">
                <div className="mews-CabsSelector-List">
                    {listItems}
                </div>
                <button className="btn btn-default btn-l mews-CabsSelector-ItemAdd" onClick={this.props.onAddCabClick}>weitere CABIN hinzufügen</button>
            </div>
        );
    }
};

export default CabsSelector;